<template>
  <div>
    <v-textarea
      v-model="myValue"
      :rows="rows"
      :placeholder="placeholder"
      :counter="counter"
      :solo="solo"
      :no-resize="noResize"
      :hide-details="hideDetails"
      :rules="rules"
      :ref="refTextarea"
      @input="handleInput"
    />
    <v-dialog
      v-model="dialog.display"
      persistent
      width="fit-content"
    >
      <v-card class="textarea-at-card">
        <v-list class="white">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title :style="{'font-size': '20px'}">
                <b>@</b>
              </v-list-item-title>
            </v-list-item-content>

            <v-spacer></v-spacer>

            <v-list-item-action>
              <v-btn text icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list> 

        <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: visible;">
        <div style="text-align: left;padding: 16px 16px 3px 16px;">
          Please input and select user.
        </div>
        <div
          class="textarea-at-content-container"
          :style="{ 'padding': '4px 16px 24px 16px' }"
        >
          <account-autocomplete
            v-model="dialog.user"
            label=""
            :hide-details="true"
            :rules="[v => !!v.email || 'Please input and select']"
          ></account-autocomplete>
        </div>

        <div style="padding: 28px 16px 2px;text-align: right;">
          <v-btn color="primary" :disabled="!dialog.user" @click="clickDone">
            OK
          </v-btn>
        </div>
      </div>
      </v-card>
    </v-dialog>       
    <div
      tabindex="0"
      :ref="refAtCardFocus"
      style="height: 0px;"
    ></div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
// import MsalFunctions from '@/components/msal/functions/index.vue'
import AccountAutocomplete from '@/components/vuetify-extension/account-autocomplete'

export default {
  components: {
    // MsalFunctions,
    AccountAutocomplete,
  },
  props: {
    // data
    value: {
      type: String
    },

    // props: v-textarea
    rows: {
      type: [Number, String]
    },
    placeholder: {
      type: String
    },
    counter: {
      type: [Boolean, Number, String]
    },
    solo: {
      type: Boolean
    },
    noResize: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean
    },
    rules: {
      type: Array
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  data () { return {
    // data
    myValue: '',
    dialog: {
      display: false,
      user: null,
    },

    // params
    refNo: Date.parse(new Date()),
  }},
  computed: {
    refTextarea () {
      return `textarea-at-${this.refNo}`
    },
    refAtCardFocus () {
      return `textarea-at-card-focus-${this.refNo}`
    },
  },
  methods: {
    // text input
    handleInput () {
      if (this.myValue[this.myValue.length-1] === '@') {
        this.$refs[this.refAtCardFocus].focus()
        this.openDialog()
      }
    },

    // dialog
    clearDialog () {
      this.dialog.user = null
    },
    openDialog () {
      this.clearDialog()
      this.dialog.display = true
    },
    closeDialog () {
      this.dialog.display = false
      this.clearDialog()
    },
    clickDone () {
      if (!this.dialog.user?.email || !this.dialog.user?.name) {
        this.$eventBus.$emit('snackbar', { text: 'Invalid User', type: 'error' })
        return
      }
      this.myValue = this.myValue + this.dialog.user?.name + ' (' + this.dialog.user?.email + ') '
      this.closeDialog()
    },
  },
  watch: {
    value: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.myValue = this.value
        }
      }
    },
    myValue: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.$emit('change', nv)
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .textarea-at-card
    width: 500px !important
    height: 270px !important

  .textarea-at-content-container
    .v-input
      padding-top: 0px
    

    .v-input__slot
      padding: 0 9px 9px
    

    .person-select
      .v-input__slot
        padding: 9px
      
      .v-select__selections
        padding-left: 6px
      
    textarea
      margin-top: 0px !important
      padding-top: 8px !important
</style>
